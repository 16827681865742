.contacts {
  height: 100%;
  background-image: url("../../Assets/Images/ContactsPage/Contacts.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 108px 0 180px 0;
}
.contacts_title {
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.contacts_desc {
  color: var(--Colors-Foreground-fg-disabled, #98A2B3);
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.input {
  display: flex;
  margin: 30px var(--spacing-none, 0px) 16px var(--spacing-none, 0px);
  align-self: stretch;
  border: none;
  border-bottom: 1px solid #667085;
  background: transparent;
  height: 80px;
  width: 560px;
  color: var(--Colors-Text-text-white, #FFF);
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.32px;
  align-items: center !important;
}
.input div {
  display: flex;
  align-items: center;
}
.input div button {
  background: transparent;
  border: none;
  margin: auto;
}
.input div button:hover {
  background: transparent;
}
.input div ul {
  background-color: black !important;
  color: white !important;
  border: none;
  outline: none;
}
.input input {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  background-color: transparent !important;
  color: var(--Colors-Text-text-white, #FFF) !important;
}
.input::placeholder {
  color: var(--Colors-Text-text-white, #FFF);
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.32px;
}
.input:focus {
  outline: none;
}

.notification {
  animation: fadeInOut 3s ease-in-out;
  background-color: #f0f8ff;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
}

.button {
  color: var(--colors-base-primary-foreground, #FFF);
  font-family: Gilroy, sans-serif;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 240px;
  height: 42px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: transparent;
  transition: color 0.5s ease;
  margin-top: 40px;
}
.button::before {
  content: "";
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
  transition: bottom 0.5s ease-in-out;
}
.button:hover {
  color: var(--colors-base-primary-foreground, #000);
}
.button:hover::before {
  bottom: 0;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 1200px) {
  .contacts {
    background: var(--Component-colors-Alpha-alpha-black-100, #000);
  }
}
@media (max-width: 1200px) {
  .contacts {
    height: auto;
    padding: 32px 0 78px 0;
  }
  .contacts_title {
    font-size: 28px;
  }
  .contacts_desc {
    font-size: 14px;
  }
  .input {
    width: 100%;
  }
}

/*# sourceMappingURL=ContactsBlock.module.css.map */
